<template>
  <div class="member-info">
    <!-- 用户头像和名称 -->
    <div class="user-header">
      <img class="avatar" :src="memberInfo.avatar" alt="">
      <div class="user-title">
        <h2>{{ memberInfo.name }}</h2>
        <p class="join-date" v-if="memberInfo.isCreator">{{ memberInfo.createTime | formatDate }} 创建该家庭</p>
        <p class="join-date" v-else>{{ memberInfo.inviterTime | formatDate }} 由 {{ memberInfo.inviterName }} 邀请加入家庭</p>
      </div>
    </div>

    <!-- 信息列表 -->
    <div class="info-list">
      <div class="info-item">
        <span class="label">用户ID</span>
        <span class="value">{{ memberInfo.userId }}</span>
      </div>
      
      <div class="info-item">
        <span class="label">姓名</span>
        <span class="value">{{ memberInfo.name }}</span>
      </div>
      
      <div class="info-item">
        <span class="label">备注</span>
        <span class="value">{{ memberInfo.remarks }}</span>
      </div>
      
      <div class="info-item" @click="goToUpdateRole">
        <span class="label">家庭权限</span>
        <div class="value with-arrow">
          <span>{{ memberInfo.role }}</span>
          <van-icon v-if="userInfo.role === '管理员'" class="arrow-right" name="arrow" />
        </div>
      </div>
      
      <div class="info-item" v-if="userInfo.isCreator && userInfo.userId === memberInfo.userId" @click="goToManageFamily">
        <span class="label">管理家庭</span>
        <div class="value with-arrow">
          <van-icon class="arrow-right" name="arrow" />
        </div>
      </div>
    </div>

    <!-- 移除成员按钮 -->
    <div class="remove-member-btn" v-if="userInfo.isCreator && userInfo.userId !== memberInfo.userId">
      <button @click="showRemoveMemberDialog = true">移除成员</button>
    </div>
    <van-overlay :show="showRemoveMemberDialog" @click="hideRemoveMemberDialog">
      <div class="remove-member-dialog" @click.stop>
        <p class="remove-member-dialog__text">确认从家庭移除"{{ memberInfo.name }}"?</p>
        <div class="remove-member-dialog__buttons">
          <button class="remove-member-dialog__cancel" @click="hideRemoveMemberDialog">取消</button>
          <button class="remove-member-dialog__confirm" @click="confirmRemoveMember">移除</button>
        </div>
      </div>
    </van-overlay>
    
    <!-- 权限弹窗 -->
    <van-dialog
      v-model:show="isUpdateRoleDialogVisible"
      title="请选择权限"
      show-cancel-button
      confirm-button-text="确定"
      @confirm="confirmUpdateRole"
      :close-on-click-overlay="true"
    >
        <div class="role-dialog__content">
            <van-cell-group :border="false">
                <van-cell clickable @click="selectedRole = '成员'">
                    <template #title>
                        <div class="role-option">
                            <div class="role-option__content">
                                <div class="role-name">成员</div>
                                <div class="role-desc">可监测孩子的训练结果，可使用分享及兑换功能</div>
                            </div>
                            <van-radio class="role-radio" :name="'成员'" v-model="selectedRole" />
                        </div>
                    </template>
                </van-cell>
                <van-cell clickable @click="selectedRole = '管理员'">
                    <template #title>
                        <div class="role-option">
                            <div class="role-option__content">
                                <div class="role-name">管理员</div>
                                <div class="role-desc">可使用同屏控制，对其他成员有管理权限等，一个家庭组仅限一名管理员，请慎重转让管理员身份。</div>
                            </div>
                            <van-radio class="role-radio" :name="'管理员'" v-model="selectedRole" />
                        </div>
                    </template>
                </van-cell>
            </van-cell-group>
        </div>
    </van-dialog>
  </div>
</template>

<script>
import { getMemberInfo, delMember, updateMemberRole } from '@/api/family'
import { Icon, Overlay, Dialog, Cell, CellGroup, Radio } from 'vant'
import moment from 'moment'
export default {
  name: 'MemberInfo',
  components: {
    // 注册组件
    [Icon.name]: Icon,
    [Overlay.name]: Overlay,
    [Dialog.name]: Dialog,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Radio.name]: Radio
  },
  
  filters: {
    formatDate(v) {
      return moment(v).format('yyyy年MM月DD日 HH:mm:ss')
    }
  },
  data() {
    return {
      showRemoveMemberDialog: false,
      isUpdateRoleDialogVisible: false,
      selectedRole: '成员',
      userInfo: {},
      memberInfo: {
        avatar: '', // 头像URL
        name: '',
        inviterName: '',
        inviteTime: '',
        createTime: '',
        userId: '',
        remarks: '未设置',
        role: ''
      }
    }
  },
  methods: {
    hideRemoveMemberDialog() {
      this.showRemoveMemberDialog = false
    },
    confirmRemoveMember() {
      // 实现移除成员的逻辑
      delMember({memberId: this.memberInfo.memberId}).then(res => {
        this.$toast.success('移除成功')
        this.hideRemoveMemberDialog()
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
      }).catch(err => {
        this.$toast.clear()
        this.$toast(err.msg)
      })
    },
    goToUpdateRole() {
      if (this.userInfo.role === '管理员') {
        this.selectedRole = this.memberInfo.role
        this.isUpdateRoleDialogVisible = true
      }
    },
    goToManageFamily() {
      this.$router.push('/family/manage')
    },
    confirmUpdateRole() {
      updateMemberRole({memberId: this.memberInfo.memberId, role: this.selectedRole}).then(res => {
        this.$toast('修改成功')
        this.isUpdateRoleDialogVisible = false
        this.initData()
      }).catch(err => {
        this.$toast(err.msg)
      })
    },
    initData() {
      getMemberInfo({memberId: this.$route.query.memberId}).then(res => {
        this.memberInfo = res;
      }).finally(() => {
        this.$toast.clear()
      })
    }
  },
  mounted() {
    this.$toast.loading({
      message: '加载中...',
      forbidClick: true,   // 禁止背景点击
      duration: 0,  //展示时间，0为持续展示
      overlay: true,  //是否显示背景遮罩层
    })
    this.initData()
    const userInfo = localStorage.getItem('userInfo')
    if (userInfo) {
        this.userInfo = JSON.parse(userInfo)
    }
  } 
}
</script>

<style scoped lang="less">
.member-info {
  padding: .66rem .5rem 2rem;
  
  .user-header {
    display: flex;
    align-items: center;
    padding-bottom: .14rem;
    
    .avatar {
      width: 1.3rem;
      height: 1.3rem;
      border-radius: 50%;
      margin-right: .36rem;
      display: block;
    }
    
    .user-title {
      h2 {
        margin: 0;
        font-size: .44rem;
        line-height: .52rem;
        font-weight: 400;
        color: #000;
      }
      
      .join-date {
        color: #a7a7a7;
        font-size: .2rem;
        margin: .2rem 0 0 0;
      }
    }
  }
  
  .info-list {
    .info-item {
      display: flex;
      justify-content: space-between;
      border-bottom: .02rem solid #ebebeb;
      padding: .4rem 0 .24rem;
      .label {
        font-size: .32rem;
        color: #000;
        font-weight: 400;
        line-height: .38rem;
      }
      
      .value {
        color: #666;
        font-size: .32rem;
        line-height: .44rem;
        font-weight: 500;
        &.with-arrow {
          display: flex;
          align-items: center;
          
          .arrow-right {
            margin-left: .22rem;
            color: #d0d0d0;
          }
        }
      }
    }
  }
  
  .remove-member-btn {
    position: fixed;
    bottom: .88rem;
    left: .94rem;
    right: .94rem;
    
    button {
      width: 100%;
      background: #F1F1F1;
      border: none;
      border-radius: .58rem;
      color: #FA5151;
      font-size: .36rem;
      line-height: .84rem;
      height: .84rem;
    }
  }
}

.remove-member-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6.72rem;
  background: #fff;
  border-radius: .5rem;
  text-align: center;
  padding-bottom: .26rem;
  
  &__text {
    padding: .58rem 0 .76rem;
    margin: 0;
    font-size: .36rem;
    line-height: .5rem;
    color: #313131;
  }
  
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 .4rem;
    
    button {
      width: 2.84rem;
      height: .8rem;
      border: none;
      background: none;
      font-size: .36rem;
      background: #EDEDED;
      border-radius: .4rem;
    }
  }
  
  &__cancel {
    color: #5c5c5c;
  }
  
  &__confirm {
    color: #E54848;
  }
}

.role-dialog__content {
    padding: 0.1rem;
    .role-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .role-option__content {
            flex: 1;
        }
        .role-name {
            font-size: .28rem;
            line-height: .32rem;
            color: #333;
            font-weight: 600;
            margin-bottom: .1rem;
        }

        .role-desc {
            font-size: .26rem;
            color: #666;
            line-height: .32rem;
        }
        .role-radio {
            margin-left: .2rem;
            width: .36rem;
            height: .36rem;
        }   
    }
    :deep(.van-cell) {
        padding: 0 0 .2rem 0;
        &::after {
            display: none;
        }
    }
  }
</style>
